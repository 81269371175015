var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-card",
        {
          staticClass: "mt-2",
          attrs: { header: "true", "header-tag": "header" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
                _c("b", [_vm._v("Informazioni")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.fields, function (field, index) {
            return _c("b-row", { key: index }, [
              _c("div", { staticClass: "col-md-4 border-bottom" }, [
                _vm._v(
                  "\n        " + _vm._s(field.label.toUpperCase()) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.renderDbRowData(field, _vm.dbRowData)) +
                    "\n      "
                ),
              ]),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-card",
        {
          staticClass: "mt-2",
          attrs: { header: "true", "header-tag": "header" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
                _c("b", [_vm._v("Abbina")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("base-select", {
                    attrs: {
                      name: "model",
                      label: "Scegli entità da abbinare",
                      options: [
                        { value: "registry", text: "Anagrafica" },
                        { value: "insurance_policy", text: "Polizza" },
                      ],
                    },
                    on: {
                      select: _vm.onSelectEntity,
                      remove: _vm.onRemoveEntity,
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.entity === "registry"
                ? _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-button-toolbar",
                          [
                            _c(
                              "b-button-group",
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "Anagrafica",
                                    label: "Anagrafica",
                                    readonly: true,
                                  },
                                  model: {
                                    value: _vm.form.registry.label,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.registry, "label", $$v)
                                    },
                                    expression: "form.registry.label",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-4 btn-quick",
                                    attrs: {
                                      size: "sm",
                                      text: "Button",
                                      variant: "lisaweb",
                                      title: "Ricerca Veloce",
                                    },
                                    on: { click: _vm.openQuickSearchRegistry },
                                  },
                                  [_c("b-icon", { attrs: { icon: "search" } })],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("quick-search-registry-modal", {
                ref: "quickSearchRegistry",
                on: { input: _vm.handleRegistryModalInput },
              }),
              _vm._v(" "),
              _vm.entity === "insurance_policy"
                ? _c("div", { staticClass: "form-group col-md-6" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "b-button-toolbar",
                          [
                            _c(
                              "b-button-group",
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "Polizza",
                                    label: "Polizza",
                                    readonly: true,
                                  },
                                  model: {
                                    value: _vm.form.insurance_policy.label,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.insurance_policy,
                                        "label",
                                        $$v
                                      )
                                    },
                                    expression: "form.insurance_policy.label",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-4 btn-quick",
                                    attrs: {
                                      size: "sm",
                                      text: "Button",
                                      variant: "lisaweb",
                                      title: "Ricerca Veloce",
                                    },
                                    on: { click: _vm.openQuickSearchPolicy },
                                  },
                                  [_c("b-icon", { attrs: { icon: "search" } })],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("quick-search-policy-modal", {
                ref: "quickSearchPolicy",
                on: { input: _vm.handlePolicyModalInput },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-row", [
            _c(
              "div",
              { staticClass: "form-group col-md-3 mt-4" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-create",
                    attrs: {
                      type: "button",
                      variant: "lisaweb",
                      size: "sm",
                      title: "Abbina",
                      disabled:
                        !_vm.form.registry.value &&
                        !_vm.form.insurance_policy.value,
                    },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v("\n          Abbina")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }